.appointment-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background:
    radial-gradient(circle, transparent 20%, #66ccff 20%, #66ccff 80%, transparent 80%, transparent),
    radial-gradient(circle, transparent 20%, #66ccff 20%, #66ccff 80%, transparent 80%, transparent) 50px 50px,
    linear-gradient(#fff 8px, transparent 8px) 0 -4px,
    linear-gradient(90deg, #fff 8px, transparent 8px) -4px 0;
    background-color: #66ccff;
    background-size:100px 100px, 100px 100px, 50px 50px, 50px 50px;
  }

  
  .appointment-form {
    width: 100%;
    max-width: 400px;
  }
  
  .appointment-form label {
    display: block;
  }
  
  .appointment-form input,
  .appointment-form select,
  .appointment-form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .appointment-form button[type="button"] {
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    text-align: center;
  }
  
  .appointment-form button[type="button"]:hover {
    background-color: #0069d9;
  }


  